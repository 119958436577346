"use client"

import Image from "next/image"
import logoSrcLight from "@/assets/images/brandmark-design (1).png"
import logoSrcDark from "@/assets/images/logo.png"
import { useRouter } from "next-nprogress-bar"
import { useStore } from "@/application/landing/components/StoreProvider"

const Logo = ({ ...props }: any) => {
  const { pageStore } = useStore()
  const router = useRouter()

  return (
    <Image
      className="logo"
      alt="Metrix"
      src={
        (props.light && logoSrcLight) ||
        (props.dark && logoSrcDark) ||
        logoSrcLight
      }
      quality={100}
      width={206}
      height={45}
      {...props}
      onClick={() => {
        router.push("/")
        pageStore.controller.onChangeActiveMenuItem("/")
      }}
    />
  )
}

export default Logo
