"use client"

import { Layout } from "antd"
import classnames from "classnames"
import { usePathname } from "next/navigation"

const onlyWords = (pathname: string) => {
  const matches = pathname.matchAll(/[\w]+/gi)

  const matchedWords: any[] = []

  // @ts-ignore
  for (const match of matches) {
    matchedWords.push(match[0])
  }

  return matchedWords.join("")
}

const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  const pathname = onlyWords(usePathname())

  return (
    <>
      <Layout
        className={classnames(
          "main-page main-page-wrapper layout-wrapper",
          pathname,
        )}
      >
        {children}
      </Layout>
    </>
  )
}

export default Wrapper
