"use client"

import { Button } from "antd"
import { useRouter } from "next-nprogress-bar"
import { Routes } from "@/services/routes"

const LoginButton = () => {
  const { push } = useRouter()

  const goToLoginPage = () => {
    push(Routes.office.user.login)
  }

  return (
    <Button onClick={goToLoginPage} className="button login-btn" type="primary">
      Login/Sign up
    </Button>
  )
}

export default LoginButton
