"use client"

import { StoreProvider } from "@/application/landing/components/StoreProvider"
import Wrapper from "@/application/landing/components/Wrapper"
import ContentWrapper from "@/application/landing/components/ContentWrapper"
import Footer from "@/application/landing/components/Footer"
import WatchMobile from "@/application/landing/components/WatchMobile"
import React from "react"
import { usePathname } from "next/navigation"
import Header from "@/application/landing/components/Header"

const LandingLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const pathname = usePathname()

  return (
    <StoreProvider>
      <WatchMobile>
        <div className="flex flex-col justify-between items-between landing-content min-h-screen">
          <Wrapper>
            <ContentWrapper>
              {pathname != "/" && <Header />}
              {children}
            </ContentWrapper>
          </Wrapper>
          <Footer />
        </div>
      </WatchMobile>
    </StoreProvider>
  )
}

export default LandingLayout
