"use client"

import { Layout } from "antd"
import Logo from "@/ui/components/Logo"
import Link from "next/link"
import Image from "next/image"
import { Routes } from "@/services/routes"

const { Footer } = Layout

const LandingFooter = () => {
  return (
    <Footer className="footer">
      <div className="footer-wrapper">
        <div className="footer-menu">
          <div className="footer-menu__logo">
            <Logo />
          </div>

          <div className="footer-menu__items">
            <div className="footer-menu__item">
              <Link href={Routes.landing.main.page}>Home</Link>
            </div>
            {/*<div className="footer-menu__item">*/}
            {/*  <Link href="">About Us</Link>*/}
            {/*</div>*/}
            <div className="footer-menu__item">
              <Link href={Routes.landing.blog.page}>Blog</Link>
            </div>
            {/*<div className="footer-menu__item">*/}
            {/*  <Link href="">FAQs</Link>*/}
            {/*</div>*/}
            <div className="footer-menu__item">
              <Link href={Routes.landing.features.page}>Features</Link>
            </div>
          </div>
        </div>

        <div className="footer__social footer-social">
          <div className="footer-social-separator" />
          <div className="footer-social__items">
            <div className="footer-social__item">
              <Link href="">
                <Image
                  src="/images/fb.svg"
                  alt=""
                  priority
                  width={32}
                  height={32}
                />
              </Link>
            </div>
            <div className="social__item">
              <Link href="">
                <Image
                  src="/images/twitter.svg"
                  alt=""
                  priority
                  width={32}
                  height={32}
                />
              </Link>
            </div>
            <div className="social__item">
              <Link href="">
                <Image
                  src="/images/instagram.svg"
                  alt=""
                  priority
                  width={32}
                  height={32}
                />
              </Link>
            </div>
            <div className="social__item">
              <Link href="">
                <Image
                  src="/images/linkedin.svg"
                  alt=""
                  priority
                  width={32}
                  height={32}
                />
              </Link>
            </div>
          </div>
        </div>

        <div className="footer-contacts-wrapper">
          <div className="footer-contacts">
            <div className="footer__contact">
              <div className="footer__contact__title">Contact Us</div>
              {/*<div className="footer__contact__text">*/}
              {/*  <a href="tel:+1 (219) 555-0114" target="_blank">+1 (219) 555-0114</a>*/}
              {/*</div>*/}
            </div>
            <div className="footer__contact">
              <div className="footer__contact__title">Email</div>
              <div className="footer__contact__text">
                <a
                  href="mailto:contact@themetrix.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact@themetrix.co
                </a>
              </div>
            </div>
          </div>
          <div className="footer-rights">
            Copyrights © 2023. Metrix Automated &Insightful
          </div>
        </div>
      </div>
    </Footer>
  )
}

export default LandingFooter
