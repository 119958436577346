"use client"

import { useEffect, useState } from "react"

let mql: MediaQueryList

export const useWatchMobile = () => {
  const isClient = typeof window !== "undefined"
  const [mobile, setMobile] = useState(
    isClient && !window.matchMedia(`(min-width: 600px)`).matches,
  )

  useEffect(() => {
    mql = window.matchMedia(`(min-width: 600px)`)

    mql.addEventListener("change", mediaQueryChanged)

    return () => mql.removeEventListener("change", mediaQueryChanged)
  }, [])

  const mediaQueryChanged = (ev: MediaQueryListEvent) => {
    setMobile(!ev.matches)
  }

  return mobile
}
