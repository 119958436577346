import { LandingPageStore } from "@/application/landing/store"
import { action, makeObservable, observable } from "mobx"

export class LandingController {
  @observable
  pageStore: LandingPageStore

  constructor(pageStore: LandingPageStore) {
    makeObservable(this)

    this.pageStore = pageStore
  }

  @action
  onChangeMobile(mobile: boolean) {
    this.pageStore.view.setMobile(mobile)
  }

  @action
  onChangeActiveMenuItem(activeKey: string) {
    this.pageStore.view.setActiveMenuItemKey(activeKey)
  }
}
