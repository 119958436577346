"use client"

import { useEffect } from "react"
import { useWatchMobile } from "@/application/landing/hooks/useMediaQueries"
import { useStore } from "@/application/landing/components/StoreProvider"
import { observer } from "mobx-react"

const WatchMobile: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isMobile = useWatchMobile()
  const { pageStore } = useStore()

  useEffect(() => {
    console.log("isMobile", isMobile)
    pageStore.controller.onChangeMobile(isMobile)
  }, [isMobile])

  return children
}

export default observer(WatchMobile)
