"use client"

import { useRouter } from "next-nprogress-bar"

interface IProps {
  route: string
}

const MenuItem: React.FC<React.PropsWithChildren & IProps> = ({
  children,
  route,
}) => {
  const router = useRouter()

  const goRoute = () => {
    router.push(route)
  }

  return (
    <div className="menu-item" onClick={goRoute}>
      {children}
    </div>
  )
}

export default MenuItem
