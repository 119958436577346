import { action, makeObservable, observable } from "mobx"
import { Routes } from "@/services/routes"

export class LandingView {
  @observable
  mobile: boolean = false
  @observable
  activeMenuKey: string = Routes.landing.main.page

  constructor() {
    makeObservable(this)
  }

  @action.bound
  setMobile(mobile: boolean) {
    this.mobile = mobile
  }

  @action.bound
  setActiveMenuItemKey(key: string) {
    this.activeMenuKey = key
  }
}
