"use client"

import { createContext, useContext } from "react"
import { LandingPageStore } from "@/application/landing/store"
import { observer, useLocalObservable } from "mobx-react"

const pageStore = new LandingPageStore()

interface RootStore {
  pageStore: LandingPageStore
}

const StoreContext = createContext<RootStore>({ pageStore })
export const StoreProvider: React.FC<React.PropsWithChildren> = observer(
  ({ children }) => {
    const pageStore_ = useLocalObservable(() => pageStore)
    return (
      <StoreContext.Provider value={{ pageStore: pageStore_ }}>
        {children}
      </StoreContext.Provider>
    )
  },
)

export const useStore = (): RootStore => {
  return useContext(StoreContext)
}
