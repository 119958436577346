export const Routes = {
  office: {
    mainPage: "/office",
    confirmEmail: "/office/confirm-email",
    report: {
      month: "/office/report/month",
      aspend: "/office/report/aspend",
      cost: "/office/report/cost",
      lifetime: "/office/report/lifetime",
    },
    user: {
      list: "/office/users",
      login: "/office/login",
      authError: "/office/login",
      successRegister: "/office/register/success",
      selectPricePlan: "/office/user/price-plan",
    },
    credentials: {
      changeEmail: "/office/credentials/change-email",
    },
    campaigns: "/office/campaigns",
    support: "/office/support",
    settings: {
      page: "/office/settings",
      personal: {
        page: "/office/settings/personal",
      },
      integrations: {
        page: "/office/settings/integrations",
      },
      privacy: {
        page: "/office/settings/privacy",
      },
      company: {
        page: "/office/settings/company",
      },
      security: {
        page: "/office/settings/security",
      },
      billing: {
        page: "/office/settings/billing",
      },
    },
  },
  api: {
    user: {
      obtainToken: "/api/user/token/",
      refreshToken: "/api/user/token-refresh/",
      retrieveInfo: "/api/user/info/",
      verifyToken: "/api/user/token-verify/",
      register: "/api/user/register/",
      activate: "/api/user/activate/",
      update: "/api/user/update-profile/",
      changeEmailSendLink: "/api/user/change-email/",
      forgotPasswordSendLink: "/api/user/forgot-password/",
      confirmChangeEmail: "/api/user/confirm-change-email/",
      confirmForgotPasswordCode: "/api/user/confirm-forgot-password/",
      changePasswordByForgot: "/api/user/change-password/",
      registerByGoogle: "/api/user/register-google/",
      retrieveHubspotSettings: "/api/user/hubspot-settings/",
    },
    integrations: {
      getList: "/api/integrations/list/",
      connect: "/api/integrations/connect/",
      disconnect: "/api/integrations/disconnect/",
      settings: {
        validate: "/api/integrations/hubspot/validate-fields/",
      },
    },
    blog: {
      get: "/api/blog/:slug/get/",
      list: "/api/blog/list/",
    },
    prices: {
      list: "/api/prices/list/",
      featuresList: "/api/prices/list-features/",
    },
    checkout: {
      process: "/api/checkout/process/",
    },
    feedback: {
      commonFeedback: "/api/feedback/common/",
    },
    reports: {
      getAll: "/api/reports/list/",
    },
  },
  landing: {
    main: {
      page: "/",
    },
    blog: {
      page: "/blog",
      detail: {
        page: "blog/:slug",
      },
    },
    prices: {
      page: "/prices",
    },
    about: {
      page: "/about",
    },
    faq: {
      page: "/faq",
    },
    features: {
      page: "/features",
    },
  },
}
