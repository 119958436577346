import { makeObservable, observable } from "mobx"
import { LandingView } from "@/application/landing/view"
import { LandingController } from "@/application/landing/controller"

export class LandingPageStore {
  @observable
  view: LandingView
  @observable
  controller: LandingController

  constructor() {
    makeObservable(this)

    this.view = new LandingView()
    this.controller = new LandingController(this)
  }
}
