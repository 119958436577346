"use client"

import { Layout, Menu } from "antd"
import Logo from "@/ui/components/Logo"
import LoginButton from "@/application/landing/components/LoginButton"
import MenuItem from "@/application/landing/components/MenuItem"
import React from "react"
import { Routes } from "@/services/routes"
import { useStore } from "@/application/landing/components/StoreProvider"
import { usePathname } from "next/navigation"
import classnames from "classnames"

const { Header } = Layout

const items = [
  {
    key: Routes.landing.main.page,
    label: <MenuItem route={Routes.landing.main.page}>Home</MenuItem>,
  },
  // {
  //   key: Routes.landing.about.page,
  //   label: <MenuItem route={Routes.landing.about.page}>About Us</MenuItem>
  // },
  {
    key: Routes.landing.blog.page,
    label: <MenuItem route={Routes.landing.blog.page}>Blog</MenuItem>,
  },
  {
    key: Routes.landing.prices.page,
    label: <MenuItem route={Routes.landing.prices.page}>Prices</MenuItem>,
  },
  // {
  //   key: Routes.landing.faq.page,
  //   label: <MenuItem route={Routes.landing.faq.page}>FAQs</MenuItem>
  // },
  {
    key: Routes.landing.features.page,
    label: <MenuItem route={Routes.landing.features.page}>Features</MenuItem>,
  },
]

interface IProps {
  className?: string
}

const LandingHeader = ({ className }: IProps) => {
  const pathname = usePathname()
  const { pageStore } = useStore()

  return (
    <>
      <div className={classnames("main-page__header-wrapper", className)}>
        <div className="main-page__header-left">
          <div>
            <Logo />
          </div>
        </div>
        <Header className="main-page__header">
          <Menu
            mode="horizontal"
            selectedKeys={[pathname]}
            items={items}
            style={{ minWidth: 0 }}
            disabledOverflow
            onSelect={(selectedItem) => {
              pageStore.controller.onChangeActiveMenuItem(selectedItem.key)
            }}
          />
        </Header>
        <div className="main-page__header-right">
          <LoginButton />
        </div>
      </div>
    </>
  )
}

export default LandingHeader
